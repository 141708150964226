.image-frame {
    width: 94%;
    margin-left: auto;
}

.image-frame .image-section {
    position: relative;
    width: 274px;
    height: 247px;
    right: 24px;
}

.image-frame .image-section  img {
    max-width: unset !important;
    height: 100%;
}

.image-frame .text-content {
    margin-left: -16px;
}